<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Role wise document permissions
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-card class="card-shadow border-radius-xl">
                  <v-card-text class="card-padding">
                    <v-form ref="frm">
                      <v-row>
                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Role
                          </label>
                          <v-select
                            v-model="editedItem.role"
                            :items="roles"
                            return-object
                            item-text="role"
                            item-value="id"
                            placeholder="Select Role"
                            :rules="[(v) => !!v || 'Role is required']"
                            @change="getAllowedDocumentPermissionByRole()"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              select-style
                              mt-3
                            "
                            outlined
                            height="46"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.role
                                }}</span>
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row v-show="show_table">
                        <v-col>
                          <!-- <v-data-table
                            :headers="headers"
                            :items="documents"
                            item-key="id"
                            class="table"
                            :footer-props="{
                              'items-per-page-options': [100, 200, 500, -1],
                            }"
                            mobile-breakpoint="0"
                          >
                          </v-data-table> -->
                          <v-simple-table
                            class="table"
                            style="border: none"
                            v-if="editedItem.role"
                          >
                            <!-- <template slot="headers" slot-scope="props"> -->
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th width="200">
                                    <v-checkbox
                                      v-model="checkDocuments"
                                      primary
                                      hide-details
                                      label="Document"
                                      @click.native="toggleAllDocument"
                                    ></v-checkbox>
                                  </th>

                                  <!-- <th class="text-left">Document</th> -->
                                  <th
                                    width="200"
                                    v-for="(item, i) in documentPermission"
                                    :key="item.id"
                                    class="text-left"
                                  >
                                    <v-checkbox
                                      v-model="checkPermissions[i].check"
                                      primary
                                      hide-details
                                      :label="item.document_permission_name"
                                      @click.native="toggleAllPermission(i)"
                                    >
                                    </v-checkbox>

                                    <!-- {{ item.document_permission_name }}
                                    access -->
                                  </th>
                                </tr>
                              </thead>
                              <!-- </template>
                            
                            <template slot="items" slot-scope="props"> -->
                              <tbody style="border: none">
                                <tr
                                  style="border: none"
                                  v-for="(item, i) in documents"
                                  :key="item.name"
                                >
                                  <td width="50" style="border: none">
                                    <v-checkbox
                                      @change="updateDocumentCheckAll()"
                                      primary
                                      hide-details
                                      :label="item.app_module_name"
                                      v-model="documents[i].isSelected"
                                    ></v-checkbox>
                                  </td>
                                  <!-- <td
                                    width="300"
                                    style="border: none"
                                    class="pt-4"
                                  >
                                    <span
                                      class="mt-1"
                                      v-if="!documents[i].isSelected"
                                      style="color: #9e9e9e"
                                    >
                                      {{ item.app_module_name }}
                                    </span>
                                    <span class="mt-1" v-else>
                                      {{ item.app_module_name }}
                                    </span>
                                  </td> -->
                                  <td
                                    style="border: none"
                                    v-for="(dp, j) in documents[i]
                                      .selected_permission"
                                    :key="dp.id"
                                  >
                                    <span>
                                      <v-checkbox
                                        :disabled="!documents[i].isSelected"
                                        v-model="
                                          documents[i].selected_permission[j]
                                            .isSelected
                                        "
                                        label="Allow"
                                        hide-details
                                        @change="updatePermissionCheckAll(j)"
                                      >
                                      </v-checkbox>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        py-3
                        px-6
                      "
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="save"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                      "
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import api from "../api";
import { mapGetters } from "vuex";

export default {
  name: "paginated-tables",
  data() {
    return {
      checkDocuments: false,
      checkPermissions: [],
      overlay: false,
      show_table: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      roles: [],
      items: [],
      app_module: [],
      all_documents: [],
      search: "",
      editedIndex: -1,
      documents: [],

      documentPermission: [],
      allowedDocumentPermission: [],
      selected: [],
      editedItem: {
        role: "",
        app_module: [],
        id: 0,
      },
      defaultItem: {
        role: "",
        app_module: [],
        id: 0,
      },
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    toggleAllDocument() {
      this.documents.map((item, i) => {
        this.documents[i].isSelected = this.checkDocuments;
      });
    },
    updateDocumentCheckAll() {
      const selected_count = this.documents.filter(
        (obj) => obj.isSelected === true
      ).length;
      if (selected_count === this.documents.length) {
        this.checkDocuments = true;
      } else {
        this.checkDocuments = false;
      }
    },

    updatePermissionCheckAll(j) {
      const selected_count = this.documents.filter(
        (obj) => obj.selected_permission[j].isSelected === true
      ).length;
      if (selected_count === this.documents.length) {
        this.checkPermissions[j].check = true;
      } else {
        this.checkPermissions[j].check = false;
      }
    },

    toggleAllPermission(index) {
      this.documents.map((item, i) => {
        this.documents[i].selected_permission[index].isSelected =
          this.checkPermissions[index].check;
      });
    },

    validate() {
      return this.$refs.frm.validate();
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      try {
        this.roles = await api.get();
        this.app_module = await api.getAppModule();
        this.documentPermission = await api.getDocumentPermission();

        // For select all permission checkbox in the top
        this.documentPermission.map(() => {
          this.checkPermissions.push({ check: false });
        });

        this.all_documents = await api.getAppModuleDocuments();

        this.setDocuments();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },
    async setDocuments() {
      this.checkDocuments = false;
      this.documents = [];
      try {
        await this.all_documents.map(async (item) => {
          let selected_permission = [];
          await this.documentPermission.map((item) => {
            selected_permission.push({ id: item.id, isSelected: false });
          });
          this.documents.push({
            ...item,
            selected_permission,
            isSelected: false,
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getAllowedDocumentPermissionByRole() {
      this.overlay = true;
      this.setDocuments();
      try {
        this.show_table = false;
        this.allowedDocumentPermission =
          await api.getAllowedDocumentPermissionByRole(this.editedItem.role.id);
        await this.documents.map(async (item, doc_index) => {
          let allowed_index = await this.allowedDocumentPermission.findIndex(
            (a) => {
              return item.document_id === a.document_id;
            }
          );

          if (allowed_index > -1) {
            let allowed_item = this.allowedDocumentPermission[allowed_index];
            this.documents[doc_index].isSelected = true;

            if (allowed_item.permissions != null) {
              if (item.selected_permission) {
                await item.selected_permission.map((per) => {
                  let per_index = allowed_item.permissions.findIndex((b) => {
                    return b.document_permission_id === per.id;
                  });
                  if (per_index > -1) {
                    this.documents[doc_index].selected_permission[
                      per_index
                    ].isSelected = true;
                  } else {
                    this.documents[doc_index].selected_permission[
                      per_index
                    ].isSelected = false;
                  }
                });
              }
            }
          } else {
            this.documents[doc_index].isSelected = false;
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.overlay = false;
        this.show_table = true;
        this.updateDocumentCheckAll();

        for (let index = 0; index < this.documentPermission.length; index++) {
          this.updatePermissionCheckAll(index);
        }
      }
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Role Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },
    reset() {
      this.$refs.frm.reset();
    },
    close() {
      this.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const selected_count = this.documents.filter(
        (obj) => obj.isSelected === true
      ).length;

      if (selected_count > 0) {
        this.overlay = true;
        let _doc_permissions = [];
        const selected_documents = await this.documents.filter(
          (obj) => obj.isSelected === true
        );
        let input_data = {
          // role: this.editedItem.role.role,
          app_module: selected_documents,
        };

        const with_permission = await selected_documents.filter(
          (obj) => obj.selected_permission != null
        );

        await with_permission.map(async (item) => {
          let input_permission = null;
          let permissions = [];
          await item.selected_permission.map((per) => {
            if (per.isSelected) {
              permissions.push({ id: per.id });
            }
          });

          if (permissions.length > 0) {
            input_permission = {
              role: this.editedItem.role,
              document: { id: item.document_id },
              document_permission: permissions,
            };

            _doc_permissions.push(input_permission);
          }
        });

        if (this.validate()) {
          try {
            let type = "Saved";

            await api.edit(this.editedItem.role.id, {
              role: this.editedItem.role.name,
              app_module: selected_documents,
            });
            await api.add_document_permission({ data: _doc_permissions });
            this.showSuccessAlert(`Role access ${type}.`);
          } catch (err) {
            this.showErrorAlert(err.message);
          } finally {
            this.overlay = false;
          }
          this.close();
        }
      } else {
        this.$swal({
          title: "Warning",
          text: "No Document selected",
          type: "warning",
          timer: 3000,
          icon: "warning",
          showConfirmButton: false,
        });
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.app_module = [];
        } else {
          this.editedItem.app_module = this.app_module.slice();
        }
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),

    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    selectAll() {
      return this.editedItem.app_module.length === this.app_module.length;
    },
    selectSome() {
      return this.editedItem.app_module.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
