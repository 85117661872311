import axios from "axios";
const API = "role";

export default {
  async get() {
    return await axios
      .get(API)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  create(item) {
    return axios
      .post(API, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  delete(id) {
    return axios
      .delete(API + `/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  edit(id, item) {
    return axios
      .patch(API + `/${id}`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAppModule() {
    return await axios
      .get("application-module")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAppModuleDocuments() {
    return await axios
      .get("application-module/document-module")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getDocumentPermission() {
    return await axios
      .get("document-permission")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAllowedDocumentPermissionByRole(id) {
    return await axios
      .get("document-role-permission/document-by-role/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  add_document_permission(item) {
    return axios
      .post(`document-role-permission/bulk`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
