var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"green","indeterminate":""}})],1),_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Role wise document permissions ")])])])]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('v-card-text',{staticClass:"card-padding"},[_c('v-form',{ref:"frm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Role ")]),_c('v-select',{staticClass:"\n                            input-style\n                            font-size-input\n                            text-light-input\n                            placeholder-light\n                            select-style\n                            mt-3\n                          ",attrs:{"items":_vm.roles,"return-object":"","item-text":"role","item-value":"id","placeholder":"Select Role","rules":[function (v) { return !!v || 'Role is required'; }],"outlined":"","height":"46"},on:{"change":function($event){return _vm.getAllowedDocumentPermissionByRole()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-text',{staticClass:"py-1 px-2 my-0",attrs:{"name":"ss_name","label":"","color":"bg-default"}},[_c('span',{staticClass:"text-caption ls-0"},[_vm._v(_vm._s(item.role))])])]}}]),model:{value:(_vm.editedItem.role),callback:function ($$v) {_vm.$set(_vm.editedItem, "role", $$v)},expression:"editedItem.role"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_table),expression:"show_table"}]},[_c('v-col',[(_vm.editedItem.role)?_c('v-simple-table',{staticClass:"table",staticStyle:{"border":"none"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"width":"200"}},[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","label":"Document"},nativeOn:{"click":function($event){return _vm.toggleAllDocument.apply(null, arguments)}},model:{value:(_vm.checkDocuments),callback:function ($$v) {_vm.checkDocuments=$$v},expression:"checkDocuments"}})],1),_vm._l((_vm.documentPermission),function(item,i){return _c('th',{key:item.id,staticClass:"text-left",attrs:{"width":"200"}},[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","label":item.document_permission_name},nativeOn:{"click":function($event){return _vm.toggleAllPermission(i)}},model:{value:(_vm.checkPermissions[i].check),callback:function ($$v) {_vm.$set(_vm.checkPermissions[i], "check", $$v)},expression:"checkPermissions[i].check"}})],1)})],2)]),_c('tbody',{staticStyle:{"border":"none"}},_vm._l((_vm.documents),function(item,i){return _c('tr',{key:item.name,staticStyle:{"border":"none"}},[_c('td',{staticStyle:{"border":"none"},attrs:{"width":"50"}},[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","label":item.app_module_name},on:{"change":function($event){return _vm.updateDocumentCheckAll()}},model:{value:(_vm.documents[i].isSelected),callback:function ($$v) {_vm.$set(_vm.documents[i], "isSelected", $$v)},expression:"documents[i].isSelected"}})],1),_vm._l((_vm.documents[i]
                                    .selected_permission),function(dp,j){return _c('td',{key:dp.id,staticStyle:{"border":"none"}},[_c('span',[_c('v-checkbox',{attrs:{"disabled":!_vm.documents[i].isSelected,"label":"Allow","hide-details":""},on:{"change":function($event){return _vm.updatePermissionCheckAll(j)}},model:{value:(
                                        _vm.documents[i].selected_permission[j]
                                          .isSelected
                                      ),callback:function ($$v) {_vm.$set(_vm.documents[i].selected_permission[j]
                                          , "isSelected", $$v)},expression:"\n                                        documents[i].selected_permission[j]\n                                          .isSelected\n                                      "}})],1)])})],2)}),0)]},proxy:true}],null,false,1899195211)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-end"},[_c('v-btn',{staticClass:"\n                      font-weight-bold\n                      text-capitalize\n                      btn-ls btn-secondary\n                      bg-light\n                      py-3\n                      px-6\n                    ",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"\n                      text-capitalize\n                      btn-ls btn-primary\n                      bg-success\n                      py-3\n                      px-6\n                    ",attrs:{"elevation":"0","ripple":false,"height":"43","dark":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }